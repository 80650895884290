import { Result } from "antd";

const PageNotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="お探しのページが見つかりません。"
    />
  );
};

export default PageNotFound;
